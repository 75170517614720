import { ApolloClient, HttpLink, InMemoryCache, split } from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";

const httpLink = new HttpLink({
  uri: process.env.SEVER_GQL_PATH ?? "http://localhost:4000/graphql",
});

// TODO: may need to authenticate over websocket (https://www.apollographql.com/docs/react/data/subscriptions#5-authenticate-over-websocket-optional)
const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.SERVER_WEBSOCKET_PATH ?? "ws://localhost:4000/graphql",
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

export const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});
