import { createTheme } from "@mui/material";
import felt from "../src/images/felt.png";

export const theme = createTheme({
  typography: { fontFamily: "monospace" },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "white",
          "&.Mui-focused": {
            color: "white",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: "white",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "white",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "white",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "white",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          borderColor: "white",
          color: "white",
          "&:hover": {
            borderColor: "white",
            backgroundColor: "rgba(255, 255, 255, 0.25)",
          },
          "&.Mui-disabled": {
            color: "rgba(255, 255, 255, 0.25)",
            borderColor: "rgba(255, 255, 255, 0.25)",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: `url(${felt})`,
          color: "white",
          border: "solid 5px #663931",
          borderRadius: "12px",
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: "white",
        },
      },
    },
  },
});
