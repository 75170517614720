import * as React from "react";
import { useEffect, useState } from "react";
import { Player, Table } from "craps-server/src/gql/graphql";
import { gql, useMutation, useQuery, useSubscription } from "@apollo/client";

type GameContextType = {
  playerId: string | undefined;
  players: Player[];
  table: Table | undefined;
  tables: Table[];
  initializePlayer: (name: string, password?: string) => void;
  updatePlayerPosition: (x: number, y: number) => void;
  placeWager: (bet: string) => void;
  joinTable: (tableId: string) => void;
  leaveTable: () => void;
  rollDice: (tableId: string) => void;
  respondToShooterQuery: (accept: boolean) => void;
};

export const GameContext = React.createContext<GameContextType>({
  playerId: undefined,
  players: [],
  table: undefined,
  tables: [],
  initializePlayer: (name, password) => {},
  updatePlayerPosition: (x, y) => {},
  placeWager: (bet) => {},
  joinTable: (tableId) => {},
  leaveTable: () => {},
  rollDice: () => {},
  respondToShooterQuery: (accept) => {},
});

export function GameProvider({ children }: { children: React.ReactNode }) {
  const [playerId, setPlayerId] = useState<string | undefined>();
  const [players, setPlayers] = useState<Player[]>([]);
  const [table, setTable] = useState<Table | undefined>();
  const [tables, setTables] = useState<Table[]>([]);

  const GAME_FIELDS = gql`
    fragment PlayerFields on Player {
      id
      name
      credits
      position {
        x
        y
      }
      direction
      wagers {
        bet
        units
        amount
      }
    }

    fragment TableFields on Table {
      id
      type
      name
      position {
        x
        y
      }
      table {
        point
        roll
        dice
        shooter {
          playerId
          confirmed
          inactive
        }
      }
      playerIds
    }
  `;

  const { data: queryData } = useQuery(gql`
    query {
      players {
        ...PlayerFields
      }
      tables {
        ...TableFields
      }
    }
    ${GAME_FIELDS}
  `);

  const { data: subscriptionData } = useSubscription(gql`
    subscription {
      game {
        players {
          ...PlayerFields
        }
        tables {
          ...TableFields
        }
      }
    }
    ${GAME_FIELDS}
  `);

  const [initializePlayer] = useMutation(gql`
    mutation ($name: String!, $password: String) {
      initializePlayer(name: $name, password: $password) {
        id
      }
    }
  `);

  const [updatePlayerPosition] = useMutation(gql`
    mutation ($id: String!, $x: Int!, $y: Int!) {
      updatePlayerPosition(playerId: $id, x: $x, y: $y) {
        position {
          x
          y
        }
        direction
      }
    }
  `);

  const [placeWager] = useMutation(gql`
    mutation ($playerId: String!, $bet: Bet!, $units: Int!) {
      placeWager(playerId: $playerId, bet: $bet, units: $units) {
        id
      }
    }
  `);

  const [joinTable] = useMutation(gql`
    mutation ($playerId: String!, $tableId: String!) {
      joinTable(playerId: $playerId, tableId: $tableId) {
        id
      }
    }
  `);

  const [leaveTable] = useMutation(gql`
    mutation ($playerId: String!, $tableId: String!) {
      leaveTable(playerId: $playerId, tableId: $tableId) {
        id
      }
    }
  `);

  const [rollDice] = useMutation(gql`
    mutation ($playerId: String!, $tableId: String!) {
      rollDice(playerId: $playerId, tableId: $tableId) {
        roll
        dice
      }
    }
  `);

  const [respondToShooterQuery] = useMutation(gql`
    mutation ($playerId: String!, $accept: Boolean!) {
      respondToShooterQuery(playerId: $playerId, accept: $accept) {
        playerId
      }
    }
  `);

  const _initializePlayer = (name: string, password?: string) => {
    initializePlayer({
      variables: { name, password },
    }).then(({ data: initializePlayerData }) =>
      setPlayerId(initializePlayerData?.initializePlayer.id)
    );
  };

  const _updatePlayerPosition = (x: number, y: number) => {
    if (playerId) {
      void updatePlayerPosition({
        variables: { id: playerId, x, y },
      });
    }
  };

  const _placeWager = (bet: string) => {
    if (playerId) {
      void placeWager({
        variables: { playerId, bet, units: 1 },
      });
    }
  };

  const _joinTable = (tableId: string) => {
    if (playerId && !table) {
      void joinTable({
        variables: { playerId, tableId },
      });
    }
  };

  const _leaveTable = () => {
    if (playerId && table) {
      void leaveTable({
        variables: { playerId, tableId: table.id },
      });
    }
  };

  const _rollDice = () => {
    if (playerId && table?.id) {
      void rollDice({
        variables: { playerId, tableId: table.id },
      });
    }
  };

  const _respondToShooterQuery = (accept: boolean) => {
    if (playerId) {
      void respondToShooterQuery({
        variables: { playerId, accept },
      });
    }
  };

  useEffect(() => {
    if (queryData?.players) {
      setPlayers(queryData.players);
    }
  }, [queryData?.players]);

  useEffect(() => {
    if (queryData?.tables) {
      setTables(queryData.tables);
    }
  }, [queryData?.tables]);

  // TODO: break into separate effects for tables/players like initial query?
  useEffect(() => {
    if (subscriptionData) {
      setPlayers(subscriptionData.game.players);
      setTables(subscriptionData.game.tables);
    }
  }, [subscriptionData]);

  useEffect(() => {
    if (!playerId) {
      return;
    }
    setTable(
      tables.find((_table) => _table.playerIds.includes(playerId)) ?? undefined
    );
  }, [tables]);

  return (
    <GameContext.Provider
      value={{
        playerId,
        players,
        table,
        tables,
        initializePlayer: _initializePlayer,
        updatePlayerPosition: _updatePlayerPosition,
        placeWager: _placeWager,
        joinTable: _joinTable,
        leaveTable: _leaveTable,
        rollDice: _rollDice,
        respondToShooterQuery: _respondToShooterQuery,
      }}
    >
      {children}
    </GameContext.Provider>
  );
}
