import * as React from "react";
import { ApolloProvider } from "@apollo/client";
import { GameProvider } from "./src/context/GameContext";
import { theme } from "./utils/MaterialUITheme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { client } from "./utils/ApolloClient";
import { Helmet } from "react-helmet";
import favicon from "./src/images/favicon.ico";

export const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <GameProvider>
        <ThemeProvider theme={theme}>
          <Helmet>
            <title>Craptactic</title>
            <link rel="icon" type="image/x-icon" href={favicon} />
          </Helmet>
          <CssBaseline />
          {element}
        </ThemeProvider>
      </GameProvider>
    </ApolloProvider>
  );
};
